import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"
import playCanvasConfigLoader from '../../loaders/playCanvasConfigLoader';

import Application from './Application/Application';

const PlayCanvas = ({ playcanvasData }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    return () => {
      setConfig(null);
    };
  }, []);

  useEffect(() => {
    // Load station data
    let mounted = true;
    let pcConfig = null;
    const loadPlayCanvasConfig = async () => {
      pcConfig = await playCanvasConfigLoader.load();
    };

    loadPlayCanvasConfig().then(() => {
      if (mounted) {
        setConfig(pcConfig);
      }
    });

    return () => {
      mounted = false;
    };
  }, [config]);

  return (
    <div className="wrapper">
      {config && (
        <Application
          config={config}
          data={{ playcanvasData }}
        />
      )}
    </div>
  );
};

PlayCanvas.propTypes = {
  playcanvasData: PropTypes.array.isRequired,
}

export default PlayCanvas;

export default class ApplicationControl {
  static init(params) {
    return new ApplicationControl(params);
  }

  constructor(params) {
    const { application } = params;
    this.application = application;
  }

  setAptData(data) {
    if (this.application) {
      this.application.scene.root.script.app.populate(data.playcanvasData);
    }
  }

  selectApt(apt) {
    if (this.application) {
      this.application.scene.root.script.app.selectApt(apt);
    }
  }

  // Pass in 1-6 value
  changeCameraAngle(id) {
    if (this.application) {
      this.application.scene.root.script.app.switchCamera(id);
    }
  }

  nextCameraView() {
    if (this.application) {
      this.application.scene.root.script.app.switchNextCamera();
    }
  }

  prevCameraView() {
    if (this.application) {
      this.application.scene.root.script.app.switchPrevCamera();
    }
  }

  deselectAllApts() {
    if (this.application) {
      this.application.scene.root.script.app.deselectAllApts();
    }
  }

  destroy() {
    this.application = null;
  }
}

import { createGlobalStyle } from "styled-components"
import { main } from "./theme"
import './fonts.css'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 16px;
    background-color: ${main.colors.white};
    margin: 0;
  }
  button {
    color: inherit;
    appearance: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`

export default GlobalStyle

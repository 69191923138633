const playCanvasPath = '../playcanvas/';
// const dataFilename = 'data.json';
let basisWASM = true;
/* eslint-disable */
// eslint-disable-next-line func-names
const loadModules = function (modules, urlPrefix, doneCallback) {
  // check for wasm module support
  function wasmSupported() {
    try {
      if (
        typeof WebAssembly === 'object' &&
        typeof WebAssembly.instantiate === 'function'
      ) {
        const module = new WebAssembly.Module(
          Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
        );
        if (module instanceof WebAssembly.Module)
          return (
            new WebAssembly.Instance(module) instanceof WebAssembly.Instance
          );
      }
    } catch (e) {}
    return false;
  }
  // load a script
  // eslint-disable-next-line no-shadow
  function loadScriptAsync(url, doneCallback) {
    const tag = document.createElement('script');
    if (url == '../playcanvas/files/assets/49360981/1/basis.wasm.js') {
      tag.id = 'basis';
    }

    if (url == '../playcanvas/files/assets/47689073/1/ammo.wasm.js') {
      tag.id = 'ammo';
    }

    tag.onload = function () {
      doneCallback();
    };
    tag.onerror = function () {
      throw new Error(`failed to load ${url}`);
    };
    tag.async = false;
    tag.src = url;
    // tag.id="ammo"
    document.head.appendChild(tag);
  }

  // load and initialize a wasm module
  function loadWasmModuleAsync(moduleName, jsUrl, binaryUrl, doneCallback) {
    if (basisWASM) {
      if (document.querySelector('#ammo') && document.querySelector('#basis')) {
        doneCallback();
        return;
      }
    } else {
      if (document.querySelector('#ammo')) {
        doneCallback();
        return;
      }
    }

    loadScriptAsync(jsUrl, function () {
      const lib = window[moduleName];
      window[`${moduleName}Lib`] = lib;
      lib({
        locateFile() {
          return binaryUrl;
        },
      }).then(function (instance) {
        window[moduleName] = instance;
        doneCallback();
      });
    });
  }

  if (typeof modules === 'undefined' || modules.length === 0) {
    // caller may depend on callback behaviour being async
    setTimeout(doneCallback);
  } else {
    let asyncCounter = modules.length;
    const asyncCallback = function () {
      asyncCounter--;
      if (asyncCounter === 0) {
        setTimeout(() => {
          doneCallback();
        },1000);
      }
    };

    const wasm = wasmSupported();
    modules.forEach(function (m) {
      if (!m.hasOwnProperty('preload') || m.preload) {
        if (wasm) {
          loadWasmModuleAsync(
            m.moduleName,
            urlPrefix + m.glueUrl,
            urlPrefix + m.wasmUrl,
            asyncCallback
          );
        } else {
          if (!m.fallbackUrl) {
            throw new Error(
              `wasm not supported and no fallback supplied for module ${m.moduleName}`
            );
          }
          loadWasmModuleAsync(
            m.moduleName,
            urlPrefix + m.fallbackUrl,
            '',
            asyncCallback
          );
        }
      } else {
        asyncCallback();
      }
    });
  }
};

const moduleLoaded = function () {
  // console.log('loaded');
  setTimeout(() => {
    return true;
  }, 1000);
};

let m = null;

function wait(ms) {
  return new Promise((r) => setTimeout(r, ms));
}
/* eslint-enable */

/* eslint-disable-next-line import/no-anonymous-default-export */
export default {
  async load(callback, loadBasisWasm = false) {
    if (document.querySelector('#ammo') && document.querySelector('#basis')) {
      callback();
      return;
    }

    basisWASM = loadBasisWasm;
    if (m) {
      await wait(0);
      return m;
    }
    // TODO move these urls to a better place
    let modules;
    if (basisWASM) {
      modules = [
        {
          moduleName: 'Ammo',
          glueUrl: `${playCanvasPath}files/assets/47689073/1/ammo.wasm.js`,
          wasmUrl: `${playCanvasPath}files/assets/47689074/1/ammo.wasm.wasm`,
          fallbackUrl: `${playCanvasPath}files/assets/47689072/1/ammo.js`,
          preload: true,
        },
        {
          moduleName: 'BASIS',
          glueUrl: `${playCanvasPath}files/assets/49360981/1/basis.wasm.js`,
          wasmUrl: `${playCanvasPath}files/assets/49360982/1/basis.wasm.wasm`,
          fallbackUrl: `${playCanvasPath}files/assets/49360980/1/basis.js`,
          preload: true,
        },
      ];
    } else {
      modules = [
        {
          moduleName: 'Ammo',
          glueUrl: `${playCanvasPath}files/assets/47689073/1/ammo.wasm.js`,
          wasmUrl: `${playCanvasPath}files/assets/47689074/1/ammo.wasm.wasm`,
          fallbackUrl: `${playCanvasPath}files/assets/47689072/1/ammo.js`,
          preload: true,
        },
      ];
    }
    return loadModules(modules, '', callback);
  },
};

import { css } from 'styled-components';
import { main } from "./theme"

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const gridLayout = css`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 0;
  column-gap: 10px;
  width: 100%;

  @media ${main.breakpoints.small} {
  column-gap: 40px;
  }
`
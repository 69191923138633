import styled from "styled-components"
import { main } from "../../styles/theme"
import { flexCenter, gridLayout } from "../../styles/mixins"

export const FormWrapper = styled.section`
  ${gridLayout}

  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 60px 30px;

  @media ${main.breakpoints.large} {
    padding: 100px 30px 0;
  }

  > h2 {
    color: ${({ theme }) => theme.colors.white};
    grid-column: 1 / -1;
  }
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / -1;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:last-of-type {
      margin-bottom: 32px;
      > div {
        margin: 0;
      }
    }
  }

  @media ${main.breakpoints.large} {
    padding: 100px 0 110px;
    grid-column: 3 / span 8;

    > div {
      flex-direction: row;
    }
  }
`
export const FormGroup = styled.div`
  position: relative;
  flex: 1;

  @media ${main.breakpoints.large} {
    &:last-of-type {
      margin-left: 20px;
    }
    &:first-of-type {
      margin-right: 20px;
    }
  }
`

export const Label = styled.label`
  position: absolute;
  left: 20px;
  top: 25px;
  color: ${({ theme }) => theme.colors.white};
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  transform: ${({ inputHasContent }) => inputHasContent && "translateY(-80%)"};
  font-size: ${({ inputHasContent }) => inputHasContent && "0.875rem"};
`
export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  padding: 24px 22px 22px;
  margin-top: 10px;
  width: 100%;
  outline: 0;

  &:focus ~ ${Label} {
    transform: translateY(-80%);
    font-size: 0.875rem;
  }
`
export const Button = styled.input`
  height: 46px;
  border-radius: 48px;
  padding: 0 30px;
  width: fit-content;
  font-size: 1.125rem;
  font-weight: normal;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  text-align: center;
  border: ${({ theme }) => `1px solid ${theme.colors.white}`};
`

export const ThankYouMessage = styled.div`
  ${flexCenter}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
  grid-column: 1 / -1;
  z-index: 2;
  visibility: ${({ showSuccessMessage }) =>
    showSuccessMessage ? "visible" : "hidden"};
  transition: visibility 0.2s ease, opacity 0.4s ease;
  opacity: ${({ showSuccessMessage }) => (showSuccessMessage ? "1" : "0")};

  > h2 {
    margin-top: 36px;
    color: ${({ theme }) => theme.colors.white};
  }
`

export const Checkbox = styled.span`
  display: flex;
  margin-bottom: 30px;
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  padding-left: 30px;

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    left: 0;
    top: 0;
    z-index: 2;
    margin: 0;
  }

  > input:checked ~ span {
    background-color: ${({ theme }) => theme.colors.white};
  }

  > input:checked ~ span:after {
    display: block;
  }

  > span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${({ theme }) => theme.colors.white};

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid ${({ theme }) => theme.colors.black};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`

export const GdprText = styled.label`
  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    transition: opacity 0.2s ease;
    margin-left: 4px;

    &:hover {
      opacity: 0.7;
    }
  }
`

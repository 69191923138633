import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import Button from "../Button/Button"
import {
  Wrapper,
  ApartmentsWrapper,
  InfoWrapper,
  ButtonsWrapper,
  ArrowButton,
  ScrollWrapper,
  Info,
  ImageWrapper,
  Arrows,
} from "./styles"
import { H2, P, NavLink } from "../../styles/typography"
import Arrow from "../../images/arrow.svg"
import Rotate from "../../images/rotate.svg"
import { getTotalRooms } from "../../utils/getTotalRooms"
import PlayCanvas from "../PlayCanvas/PlayCanvas"
import {
  useGlobalPCAptSelected,
  useGlobalCurrentHouse,
  useGlobalSelectApt,
  useGlobalNextCamera,
  useGlobalPrevCamera,
  useGlobalIntroComplete,
  useGlobalCameraTransition,
  useGlobalPreloadEnd,
} from "../../globalState"

const ApartmentSelector = ({ closeApartmentSelector, backgroundColor }) => {
  const ref = useRef(null)
  const [allApartments, setAllApartments] = useState([])
  const [freeApartmentsPorthuset, setFreeApartmentsPorthuset] = useState(0)
  const [freeApartmentsGardshuset, setFreeApartmentsGardshuset] = useState(0)
  const [totalApartmentsPorthuset, setTotalApartmentsPorthuset] = useState(0)
  const [totalApartmentsGardshuset, setTotalApartmentsGardshuset] = useState(0)
  const [currentlySelected, setCurrentlySelected] = useState(null)
  const [apt] = useGlobalPCAptSelected()
  const [currentHouse] = useGlobalCurrentHouse()
  const [, selectAptInPc] = useGlobalSelectApt()
  const [, setNextView] = useGlobalNextCamera()
  const [, setPrevView] = useGlobalPrevCamera()
  const [closing, setClosing] = useState(false)
  const [activeAnimation, setActiveAnimation] = useState(false)
  const [introComplete] = useGlobalIntroComplete()
  const [cameraInTrasition] = useGlobalCameraTransition()
  const [interactionDisabled, setInteractionDisabled] = useState(false)
  const [globalPreloadEnd] = useGlobalPreloadEnd()

  useEffect(() => {
    const currentApt = allApartments.find(apartment => apartment.aptId === apt)
    if (currentApt) {
      setActiveAnimation(true)
      // Sync with fade animation
      setTimeout(() => {
        setCurrentlySelected(currentApt)
        setActiveAnimation(false)
      }, 100)
    } else {
      setCurrentlySelected("")
    }
  }, [apt, setCurrentlySelected, allApartments])

  useEffect(() => {
    const scrollElement = ref.current
    if (scrollElement) disableBodyScroll(scrollElement)

    return () => {
      if (scrollElement) enableBodyScroll(scrollElement)
    }
  }, [ref])

  useEffect(() => {
    if (cameraInTrasition) {
      setInteractionDisabled(true)
    } else {
      setInteractionDisabled(false)
    }
  }, [cameraInTrasition, setInteractionDisabled])

  const apartmentsData = useStaticQuery(graphql`
    query {
      allSanityApartment {
        nodes {
          aptId
          aptNumber
          status
          floor
          title
          price
          hireCharge
          operationCost
          area
          ceilingHeight
          slug {
            current
          }
          apartmentType {
            type
            house {
              slug {
                current
              }
            }
          }
          planArrangement {
            asset {
              gatsbyImageData
            }
            altText
          }
        }
      }
    }
  `)

  // send this to PlayCanvas
  const data = apartmentsData.allSanityApartment.nodes

  useEffect(() => {
    let freeApartmentsPorthuset = 0
    let freeApartmentsGardshuset = 0
    let totalApartmentsPorthuset = 0
    let totalApartmentsGardshuset = 0

    const apartments = []

    data.forEach(apartment => {
      apartments.push(apartment)
      if (apartment.apartmentType.house.slug.current === "porthuset") {
        totalApartmentsPorthuset += 1
        if (apartment.status === 0) freeApartmentsPorthuset += 1
      }
      if (apartment.apartmentType.house.slug.current === "gardshuset") {
        totalApartmentsGardshuset += 1
        if (apartment.status === 0) freeApartmentsGardshuset += 1
      }
    })

    apartments.sort((a, b) => {
      if (a.aptId < b.aptId) return -1
      if (a.aptId > b.aptId) return 1
      return 0
    })

    setFreeApartmentsPorthuset(freeApartmentsPorthuset)
    setFreeApartmentsGardshuset(freeApartmentsGardshuset)
    setTotalApartmentsPorthuset(totalApartmentsPorthuset)
    setTotalApartmentsGardshuset(totalApartmentsGardshuset)
    setAllApartments(apartments)
  }, [data])

  const showNext = () => {
    if (interactionDisabled) return
    const currentIndex = allApartments.findIndex(apartment => {
      return apartment.aptId === currentlySelected.aptId
    })
    if (currentIndex === -1 || currentIndex === allApartments.length - 1) {
      selectAptInPc(allApartments[0].aptId)
    } else {
      selectAptInPc(allApartments[currentIndex + 1].aptId)
    }
  }

  const showPrev = () => {
    if (interactionDisabled) return
    const currentIndex = allApartments.findIndex(apartment => {
      return apartment.aptId === currentlySelected.aptId
    })
    if (currentIndex === -1 || currentIndex === 0) {
      selectAptInPc(allApartments[allApartments.length - 1].aptId)
    } else {
      selectAptInPc(allApartments[currentIndex - 1].aptId)
    }
  }

  return (
    <Wrapper closing={closing} ref={ref}>
      <ScrollWrapper>
        <ButtonsWrapper visible={introComplete}>
          <Button
            onClick={() => {
              setClosing(true)
              // Wait for fade out before closing
              setTimeout(() => {
                closeApartmentSelector()
              }, 510)
            }}
            isInverted
            hasBorder
            title="Stäng"
          />
          <Button
            onClick={() => {
              if (interactionDisabled) return
              setPrevView(true)
            }}
            title="Rotera"
            isInverted
            hasBorder
            icon={<Rotate />}
          />
          <Button
            onClick={() => {
              if (interactionDisabled) return
              setNextView(true)
            }}
            title="Rotera"
            isInverted
            hasBorder
            icon={<Rotate />}
          />
        </ButtonsWrapper>

        <ApartmentsWrapper>
          <PlayCanvas playcanvasData={data} />
        </ApartmentsWrapper>

        {currentlySelected && globalPreloadEnd ? (
          <InfoWrapper
            activeAnimation={activeAnimation}
            backgroundColor={
              currentlySelected.apartmentType.house.slug.current === "porthuset"
                ? "yellow"
                : "blue"
            }
          >
            <ImageWrapper activeAnimation={activeAnimation}>
              {currentlySelected.planArrangement &&
                currentlySelected.planArrangement.asset && (
                  <GatsbyImage
                    image={
                      currentlySelected.planArrangement.asset.gatsbyImageData
                    }
                    alt={currentlySelected.planArrangement.altText ?? ""}
                  />
                )}
            </ImageWrapper>
            <div>
              <div>
                <H2>{currentlySelected.title}</H2>
                {introComplete && (
                  <Arrows>
                    <ArrowButton type="button" isRotated onClick={showPrev}>
                      <Arrow />
                    </ArrowButton>
                    <ArrowButton type="button" onClick={showNext}>
                      <Arrow />
                    </ArrowButton>
                  </Arrows>
                )}
              </div>
              <Info activeAnimation={activeAnimation}>
                <P>Lägenhetsnummer: {currentlySelected.aptNumber}</P>
                <P>Våning: {currentlySelected.floor}</P>
                <P>Boarea: {currentlySelected.area ?? ""} kvm</P>
                <P>
                  Rum:{" "}
                  {currentlySelected.apartmentType &&
                    getTotalRooms(currentlySelected.apartmentType.type)}{" "}
                  rum och kök
                </P>
                <P>
                  Pris:{" "}
                  {currentlySelected.price
                    ? currentlySelected.price
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
                    : ""}{" "}
                  kr
                </P>
                <P>
                  Avgift:{" "}
                  {currentlySelected.hireCharge &&
                    currentlySelected.hireCharge
                      .toString()
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}{" "}
                  kr/mån
                </P>
                {/*<P>
                  Status:{" "}
                  {currentlySelected.status === 1
                    ? "Bokad"
                    : currentlySelected.status === 2
                    ? "Såld "
                    : "Ledig"}
                  </P>*/}
              </Info>
              {currentlySelected.slug && (
                <button
                  onClick={() => {
                    setClosing(true)
                    // Wait for fade out before closing
                    setTimeout(() => {
                      closeApartmentSelector()
                    }, 510)
                  }}
                >
                  <NavLink to={`/${currentlySelected.slug.current}/`}>
                    Gå till lägenhetssida
                  </NavLink>
                </button>
              )}
            </div>
          </InfoWrapper>
        ) : (
          globalPreloadEnd && (
            <InfoWrapper
              backgroundColor={currentHouse === "porthuset" ? "yellow" : "blue"}
            >
              <ImageWrapper>
                {currentHouse === "porthuset" ? (
                  <StaticImage
                    src="../../images/porthuset-fasad.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Porthuset"
                  />
                ) : (
                  <StaticImage
                    src="../../images/gardshuset-fasad.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP"]}
                    alt="Gårdshuset"
                  />
                )}
              </ImageWrapper>
              <div>
                <div>
                  <H2>
                    {currentHouse === "porthuset" ? "Porthuset" : "Gårdshuset"}
                  </H2>
                </div>
                <P>
                  Antal lägenheter:{" "}
                  {currentHouse === "porthuset"
                    ? totalApartmentsPorthuset
                    : totalApartmentsGardshuset}
                </P>
                {/* <P>
                  Status:{" "}
                  {currentHouse === "porthuset"
                    ? freeApartmentsPorthuset
                    : freeApartmentsGardshuset}{" "}
                  {currentHouse === "porthuset" && freeApartmentsPorthuset === 1
                    ? "ledig"
                    : freeApartmentsGardshuset === 1
                    ? "ledig"
                    : "lediga"}
                </P> */}
                <P>
                  Typer:{" "}
                  {currentHouse === "porthuset"
                    ? "3:or och 4:or"
                    : "1:or, 1,5:or och 2:or"}
                </P>
                <P>Våningar: {currentHouse === "porthuset" ? "1" : "8"}</P>
                <P>
                  Extra:{" "}
                  {currentHouse === "porthuset"
                    ? "Egen uteplats"
                    : "Takterrass"}
                </P>
              </div>
            </InfoWrapper>
          )
        )}
      </ScrollWrapper>
    </Wrapper>
  )
}

ApartmentSelector.propTypes = {
  closeApartmentSelector: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
}

export default ApartmentSelector

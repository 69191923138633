import styled from "styled-components"
import { flexCenter } from "../../styles/mixins"
import { main } from "../../styles/theme"

export const MenuButtonWrapper = styled.button`
  position: fixed;
  top: 0;
  right: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  mix-blend-mode: ${({ open }) => (open ? "normal" : "difference")};
  z-index: ${({ open }) => (open ? "12" : "4")};

  > h4 {
    color: ${({ theme }) => theme.colors.white};
    margin: 0 16px 4px 0;
    visibility: hidden;
    font-weight: 200;

    @media ${main.breakpoints.medium} {
      visibility: visible;
    }
  }
`
export const Burger = styled.div`
  ${flexCenter};
  flex-direction: column;
  height: 75px;
  width: 32px;
`

export const Line = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  height: 1px;
  width: 32px;
  margin: 2px 0;
  transform-origin: left;
  transition: opacity 0.2s, transform 0.2s, background-color 0.2s;
  transform: ${({ open, first, second, third }) =>
    !open
      ? "rotate(0) translateY(0)"
      : first
      ? "rotate(18deg) translateY(0)"
      : third
      ? "rotate(-18deg) translateY(0)"
      : "rotate(0) translateY(5px)"};
  opacity: ${({ open, second }) => (open && second ? "0" : "1")};
`

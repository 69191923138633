import * as React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const HeaderBar = styled.header`
  width: auto;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  position: fixed;
  z-index: ${({ open }) => (open ? "12" : "4")};
  mix-blend-mode: ${({ open }) => (open ? "normal" : "difference")};
  color: ${({ theme }) => theme.colors.white};
  transition: mix-blend-mode 0.2s;
  margin-top: 23px;
`

export const LogoLink = styled(props => (
  <AniLink cover bg="#C4C4C4" {...props} />
))`
  font-size: 2rem;
  line-height: 2.25rem;
  mix-blend-mode: difference;

  &:hover {
    opacity: 1;
    border-bottom: none;
  }

  > svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

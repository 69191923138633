import styled, { keyframes } from "styled-components"
import { flexCenter } from "../../styles/mixins"
import { main } from "../../styles/theme"

const fadeIn = keyframes`
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  animation: ${fadeIn} 0.7s ease;
  opacity: ${({ closing }) => (closing ? "0" : "1")};
  transition: opacity 0.5s ease;

  @media ${main.breakpoints.large} {
    height: 100vh;
    overflow-y: hidden;
  }
`

export const ScrollWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  @media ${main.breakpoints.large} {
    flex-direction: row;
  }
`

export const ButtonsWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  width: calc(100% - 60px);

  @media ${main.breakpoints.small} {
    width: auto;
  }

  > button {
    transition: opacity 0.2s ease;
    margin-right: 10px;
    flex: 1;

    @media ${main.breakpoints.small} {
      flex: auto;
    }

    &:not(:first-child) {
      opacity: ${({ visible }) => (visible ? "1" : "0")};
      pointer-events: ${({ visible }) => (visible ? "auto" : "none")};

      span {
        display: none;
      }

      svg {
        margin-right: 0;
      }

      @media ${main.breakpoints.large} {
        span {
          display: block;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    &:last-child svg {
      transform: scaleX(-1);
    }
  }
`

export const ApartmentsWrapper = styled.div`
  width: 100vw;
  height: 60vh;
  z-index: 1;

  .canvasWrapper {
    width: 100vw;
    height: 60vh;
  }

  @media ${main.breakpoints.large} {
    width: 70vw;
    height: 100vh;

    .canvasWrapper {
      width: 70vw;
      height: 100vh;
    }
  }
`

export const InfoWrapper = styled.div`
  width: 100vw;
  padding: 33px 24px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  animation: ${fadeIn} 0.5s ease;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === "blue"
      ? theme.colors.blue
      : backgroundColor === "yellow"
      ? theme.colors.yellow
      : theme.colors.white};

  h2 {
    transition: ${({ activeAnimation }) =>
      activeAnimation ? "opacity 0.1s" : "opacity 0.5s ease"};
    opacity: ${({ activeAnimation }) => (activeAnimation ? "0" : "1")};
  }

  > div > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:first-of-type {
      @media ${main.breakpoints.large} {
        flex-direction: column-reverse;
      }

      @media ${main.breakpoints.xlarge} {
        flex-direction: row;
      }
    }
  }
  > div > button > a {
    margin: 24px 0;
  }

  p {
    margin: 0;
  }

  @media ${main.breakpoints.large} {
    width: 30vw;
    padding: 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div > a {
      margin-top: 40px;
    }
  }
`
export const ImageWrapper = styled.div`
  margin-top: 36px;
  transition: ${({ activeAnimation }) =>
    activeAnimation ? "opacity 0.1s" : "opacity 0.5s ease"};
  opacity: ${({ activeAnimation }) => (activeAnimation ? "0" : "1")};

  @media ${main.breakpoints.large} {
    margin-top: 0;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  transition: ${({ activeAnimation }) =>
    activeAnimation ? "opacity 0.1s" : "opacity 0.5s ease"};
  opacity: ${({ activeAnimation }) => (activeAnimation ? "0" : "1")};

  @media ${main.breakpoints.large} {
    margin: 0 0 40px;
  }
`

export const Arrows = styled.div`
  display: flex;
  flex: 1;
  width: 110px;
  max-width: 110px;
  justify-content: space-between;
`

export const ArrowButton = styled.button`
  ${flexCenter}
  transform: ${({ isRotated }) => isRotated && "rotate(180deg)"};

  @media ${main.breakpoints.large} {
    margin-bottom: 40px;
  }

  @media ${main.breakpoints.xlarge} {
    margin-bottom: 0;
  }
`

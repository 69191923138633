import * as React from "react"
import PropTypes from "prop-types"
import {
  MenuButtonWrapper,
  Burger,
  Line
} from "./styles"
import { H4 } from "../../styles/typography"

const MenuButton = ({ onClick, isMenuOpen }) => {
  return (
    <MenuButtonWrapper onClick={onClick} open={isMenuOpen}>
      <H4>{isMenuOpen ? 'Stäng' : 'Meny'}</H4>
      <Burger open={isMenuOpen}>
        <Line first open={isMenuOpen} />
        <Line second open={isMenuOpen} />
        <Line third open={isMenuOpen} />
      </Burger>
    </MenuButtonWrapper>
  )
}

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool,
}

export default MenuButton

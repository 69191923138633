import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PcLoader, ProgressBar, Progress } from './styles';
import { StaticImage } from "gatsby-plugin-image"

const LoadingScreen = ({
  progressPercent = 0,
  preloadEnd = false,
  onComplete,
}) => {
  const [, setProgressLabel] = useState('loading');
  const [progress, setProgress] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  useEffect(() => {
    if (!preloadEnd) {
      return;
    }
    setFadeOut(true);

    const to = setTimeout(() => {
      clearTimeout(to);
      onComplete();
    }, 600);
    return () => {
      clearTimeout(to);
    };
  }, [onComplete, preloadEnd]);

  useEffect(() => {
    if (progress >= 1) {
      setProgressLabel('Initializing the experience');
    }
  }, [progress]);

  useEffect(() => {
    setProgress(progressPercent);
  }, [progressPercent]);

  return (
    <PcLoader fadeOut={fadeOut}>
      <StaticImage
          src="../../../images/loader-img.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Bostadsväljaren i miniatyr"
          placeholder="blurred"
          layout="fixed"
          width={154}
      />
      <ProgressBar>
        <Progress progress={progress} />
      </ProgressBar>
    </PcLoader>
  );
};

LoadingScreen.propTypes = {
  progressPercent: PropTypes.number.isRequired,
  preloadEnd: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default LoadingScreen;

/* eslint-disable no-underscore-dangle */
import React, { useRef, useEffect, useState } from 'react';
import { objectOf, shape } from 'prop-types';
import loadPcModules from '../../../loaders/loadPCModules';
import ApplicationControl from './ApplicationControl';
import EventBridge from './EventBridge';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import {
  useGlobalPCAptSelected,
  useGlobalNextCamera,
  useGlobalPrevCamera,
  useGlobalDeselectAllApartments,
  useGlobalSelectApt,
  useGlobalIntroComplete,
  useGlobalCameraTransition,
  useGlobalCameraTransitionBegin,
  useGlobalCameraTransitionEnd,
  useGlobalPreloadEnd
} from '../../../globalState';

const playCanvasPath = '../playcanvas/';

const promisify = (fun, args, ctx) =>
  new Promise((resolve, reject) => {
    args.push((err, data) => {
      if (err) {
        reject(err);
      }
      resolve(data);
    });
    fun.apply(ctx, args);
  });

const Application = ({ config, data }) => {
  let { pc } = window;
  let aptData = data;
  const canvasRef = useRef(null);
  const application = useRef(null);
  const applicationControl = useRef(null);
  const eventBridge = useRef(null);
  const [loadingVisible, setLoadingVisible] = useState(true);
  const [, setSelectedApt] = useGlobalPCAptSelected();
  const [onNextView, setNextView] = useGlobalNextCamera();
  const [onPrevView, setPrevView] = useGlobalPrevCamera();
  const [onDeselectAll, setDeselectAll] = useGlobalDeselectAllApartments();
  const [onSelectApt] = useGlobalSelectApt('');
  const [, setIntroComplete] = useGlobalIntroComplete();
  const [, setCameraIsInTransition] = useGlobalCameraTransition();
  const [, setCamTranstionBegin] = useGlobalCameraTransitionBegin();
  const [, setCamTranstionEnd] = useGlobalCameraTransitionEnd();
  const [, setGlobalPreloadEnd] = useGlobalPreloadEnd();

  /*
  	function basisSetDownloadConfig(glueUrl, wasmUrl, fallbackUrl) {
		downloadConfig = {
			glueUrl: glueUrl,
			wasmUrl: wasmUrl,
			fallbackUrl: fallbackUrl
		};
	}
  */

  const glueUrl = `${playCanvasPath}files/assets/49360981/1/basis.wasm.js`;
  const wasmUrl = `${playCanvasPath}files/assets/49360982/1/basis.wasm.wasm`;
  const fallbackUrl = `${playCanvasPath}files/assets/49360980/1/basis.js`;

  pc.basisSetDownloadConfig(glueUrl, wasmUrl, fallbackUrl);

  const onResize = (app, canvas) => {
    if (!canvas) {
      return;
    }

    if (!app) {
      return;
    }

    const { parentElement } = canvas;
    if (!parentElement) {
      return;
    }

    app.setCanvasFillMode(
      pc.FILLMODE_NONE,
      parentElement.offsetWidth,
      parentElement.offsetHeight
    );
  };

  const [progressPercent, setProgressPercent] = useState(0);
  const [preloadEnd, setPreloadEnd] = useState(false);

  // Prelaoding of application.
  const onPreloadProgress = (progress) => {
    const formattedProgress = progress.toFixed(2);
    setProgressPercent(formattedProgress);
  };

  const onPreloadEnd = () => {
  };

  const removeLoading = () => {
    setLoadingVisible(false);
  };

  const onStart = () => {
  };

  // const onDataInit = (app) => {
  // };

  useEffect(() => {
    if (onNextView) {
      if (applicationControl.current) {
        applicationControl.current.nextCameraView();
      }
      setNextView(false);
    }
  },[onNextView, setNextView])

  useEffect(() => {
    if (onPrevView) {
      if (applicationControl.current) {
        applicationControl.current.prevCameraView();
      }
      setPrevView(false);
    }
  },[onPrevView, setPrevView])

  useEffect(() => {
    if (onDeselectAll) {
      if (applicationControl.current) {
        applicationControl.current.deselectAllApts();
      }
      setDeselectAll(false);
    }
  },[onDeselectAll, setDeselectAll, setSelectedApt])

  useEffect(() => {
    if (onSelectApt !== '') {
      if (applicationControl.current) {
        applicationControl.current.selectApt(onSelectApt);
      }
    }
  }, [onSelectApt])

  useEffect(() => {
    let canvas = canvasRef.current;
    let mounted = true;

    if (!pc) {
      return;
    }

    if (!canvas) {
      return;
    }

    let app = null;

    // WASM modules loaded continue setting upp playcanvas.
    // console.log('load modules');
    const loadModulesComplete = () => {
      // console.log('load modules complete');
      const devices = {
        elementInput: new pc.ElementInput(canvas),
        keyboard: new pc.Keyboard(canvas),
        mouse: new pc.Mouse(canvas),
        gamepads: new pc.GamePads(),
        touch: 'ontouchstart' in canvas ? new pc.TouchDevice(canvas) : null,
      };

      const options = {
        elementInput: devices.elementInput,
        keyboard: devices.keyboard,
        mouse: devices.mouse,
        gamepads: devices.gamepads,
        touch: devices.touch,
        graphicsDeviceOptions: config.contextOptions,
        assetPrefix: config.assetPrefix || '',
        scriptPrefix: config.scriptPrefix || '',
        scriptsOrder: config.scripts || [],
      };
      app = new pc.Application(canvas, options);

      application.current = app;
      app.autoRender = true;

      app.on('preload:progress', (event) => {
        if (mounted) {
          // Remove some % because we are not fully done!
          onPreloadProgress(event - event * 0.095);
        }
      });
      app.on('preload:end', (event) => {
        if (mounted) {
          onPreloadEnd(event);
        }
      });
      app.on('data-event:data-loaded', () => {
        if (mounted) {
         // onDataInit(app, data);
        }
      });
      app.on('start', () => {
        if (mounted) {
          onResize(app, canvas);
          onStart(app, data);
        }
      });

      const promise = async () => {
        await promisify(app.configure, [config.configFilename], app);
        await promisify(app.preload, [], app);
        await promisify(app.loadScene, [config.scenePath], app);
      };

      applicationControl.current = ApplicationControl.init({
        application: app,
      });

      eventBridge.current = EventBridge.init({
        application: app,
        startCallback: () => {
          if (applicationControl.current) {
            applicationControl.current.setAptData(aptData);
          }
        },
        onIntroComplete: () => {
          // console.log('intro complete');
          setIntroComplete(true);
        },
        onCameraTransitionBegin: () => {
          // console.log('transition begin');
          setCamTranstionBegin(true);
          setCameraIsInTransition(true);
        },
        onCameraTransitionEnd: () => {
          // console.log('transition end');
          setCamTranstionEnd(true);
          setCameraIsInTransition(false);
        },
        onAptSelected: (aptName) => {
          setSelectedApt(aptName);
        },
        onMouseAptEnter: () => {
          document.body.style.cursor = 'pointer';
        },
        onMouseAptLeave: () => {

          document.body.style.cursor = 'initial';
        }
      });

      promise().then(() => {
        if (mounted) {
          setProgressPercent(1);
          const to = setTimeout(() => {
            clearTimeout(to);
            setPreloadEnd(true);
            setGlobalPreloadEnd(true);
            app.start();
          }, 100);
        }
      });

      window.addEventListener('resize', () => {
        if (mounted) {
          onResize(app, canvas);
        }
      });
      window.addEventListener('orientationchange', () => {
        if (mounted) {
          onResize(app, canvas);
        }
      });

      [
        'fullscreenchange',
        'webkitfullscreenchange',
        'mozfullscreenchange',
        'msfullscreenchange',
      ].forEach(
        (eventType) =>
          document.addEventListener(eventType, () => {
            if (mounted) {
              onResize(app, canvas);
            }
          }),
        false
      );

      // Will handle the broadcast events from playcanvas.
      applicationControl.current = ApplicationControl.init({
        application: app,
      });

    };

    // Load WASM files for ammo.js
    loadPcModules.load(loadModulesComplete, false);

    return () => {
      setIntroComplete(false);
      setGlobalPreloadEnd(false);
      app.destroy();
      mounted = false;
      if (!canvasRef.current) {
        return;
      }
      canvas = canvasRef.current;

      if (application.current) {
        try {
          application.current.destroy();
          applicationControl.current.destroy();
          application.current = null;
          eventBridge.current.destroy();
          // console.log('Application destroyed');
        } catch (error) {
          console.log('failed to destroy ', error);
        }
      }

      canvasRef.current = null;
      window.removeEventListener('resize', null);
      window.removeEventListener('orientationchange', null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="canvasWrapper">
      <canvas
        className="canvas"
        id="game"
        ref={canvasRef}
      />
      {loadingVisible && (
        <LoadingScreen
          progressPercent={Number(progressPercent)}
          preloadEnd={preloadEnd}
          onComplete={removeLoading}
        />
      )}
    </div>
  );
};

Application.propTypes = {
  config: shape().isRequired,
  data: objectOf(shape).isRequired,
};

export default Application;

import styled from "styled-components"
import { flexCenter } from "../../styles/mixins"
import { main } from "../../styles/theme"

export const Wrapper = styled.section`
  background-color: ${({ theme, inverted }) => inverted ? theme.colors.black : theme.colors.white};
  ${flexCenter}
  padding: 90px 0;
  cursor: pointer;

  > svg {
    transform: rotate(-90deg);

    path {
      stroke: ${({ theme, inverted }) => inverted ? theme.colors.white : theme.colors.black};

    }
    &:first-of-type {
      margin-right: 55px;
    }
    &:last-of-type {
      margin-left: 55px;
    }
  }

  > h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: ${({ theme, inverted }) => inverted ? theme.colors.white : theme.colors.black};

    @media ${main.breakpoints.medium} {
      font-size: 3.25rem;
      line-height: 4rem;
    }
    @media ${main.breakpoints.large} {
      font-size: 9.25rem;
      line-height: 9.25rem;
    }
  }
`

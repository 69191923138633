import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { H1style, H3, P } from "../../styles/typography"
import {
  Wrapper,
  Content,
  ImageBlock,
  HeadingWrapper,
  InfoBlock,
  ContactInfo,
} from "./styles"

const ContactBlock = ({ has4dContactInfo }) => {
  return (
    <>
      <Wrapper>
        <HeadingWrapper>
          <H1style>Kontaktuppgifter{"\n"}till mäklare</H1style>
        </HeadingWrapper>
        <Content>
          {/* <ImageBlock>
            <StaticImage
              src="../../images/malin_tulldahl_svartvit.jpg"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Malin Tulldahl"
            />
            <H3>Ansvarig mäklare</H3>
            <P>Malin Tulldahl</P>
            <P>Fastighetsmäklare</P>
            <P>
              <a href="tel:031-733 20 98">031-733 20 98</a>
            </P>
            <P>
              <a href="mailto:malin.tulldahl@bjurfors.se">
                malin.tulldahl@bjurfors.se
              </a>
            </P>
          </ImageBlock> */}
          <ImageBlock>
            <StaticImage
              src="../../images/karin_berggren_svartvit.jpg"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Karin Berggren"
            />
            <H3>Fastighetsmäklare</H3>

            <P>Karin Berggren</P>
            <P>Fastighetsmäklare</P>
            <P>
              <a href="tel:031-733 20 20">031-733 20 20</a>
            </P>
            <P>
              <a href="mailto:karin.berggren@bjurfors.se">
                karin.berggren@bjurfors.se
              </a>
            </P>
          </ImageBlock>
        </Content>
      </Wrapper>
      {has4dContactInfo && (
        <InfoBlock>
          <StaticImage
            src="../../images/om-oss.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="4D gruppen logotyp"
          />
          <ContactInfo>
            <P>4D Gruppen AB {"\n\n"}</P>
            <P>
              Besöksadress: {"\n"}Skeppsbron 5-6{"\n"}411 21 Göteborg {"\n"}{" "}
            </P>
            <P>
              <a href="mailto:info@4dgruppen.se">info@4dgruppen.se</a>
            </P>
            <P>
              <a href="tel:031 – 280 700">031 – 280 700</a>
            </P>
            <P>
              <a
                href="https://www.instagram.com/4dgruppen/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </P>
            <P>
              <a href="https://4dgruppen.se" target="_blank" rel="noreferrer">
                www.4dgruppen.se
              </a>
            </P>
          </ContactInfo>
        </InfoBlock>
      )}
    </>
  )
}

ContactBlock.propTypes = {
  has4dContactInfo: PropTypes.bool,
}

ContactBlock.defaultProps = {
  has4dContactInfo: false,
}

export default ContactBlock

export default class EventBrigde {
  static init(params) {
    return new EventBrigde(params);
  }

  constructor(params) {
    const {
      application,
      startCallback,
      onAptSelected,
      onIntroComplete,
      onCameraTransitionBegin,
      onCameraTransitionEnd,
      onMouseAptEnter,
      onMouseAptLeave,
    } = params;

    this.application = application;
    this.startCallback = startCallback;
    this.onAptSelected = onAptSelected;
    this.onIntroComplete = onIntroComplete;
    this.onCameraTransitionEnd = onCameraTransitionEnd;
    this.onCameraTransitionBegin = onCameraTransitionBegin;
    this.onMouseAptEnter = onMouseAptEnter;
    this.onMouseAptLeave = onMouseAptLeave;

    this.application.on('game:aptSelected', (aptName) => {
      this.onAptSelected(aptName);
    });

    this.application.on('game:introComplete', () => {
      this.onIntroComplete();
    });

    this.application.on('game:cameraTranstionBegin', () => {
      this.onCameraTransitionBegin();
    });

    this.application.on('game:cameraTranstionEnd', () => {
      this.onCameraTransitionEnd();
    });

    this.application.on('game:started', () => {
      this.startCallback();
    });

    this.application.on('game:aptmouseenter', () => {
      this.onMouseAptEnter();
    });

    this.application.on('game:aptmouseleave', () => {
      this.onMouseAptLeave();
    });
  }

  destroy() {
    this.application = null;
  }
}

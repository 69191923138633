import * as React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { main } from "../../styles/theme"
import { flexCenter } from "../../styles/mixins"

export const Navigation = styled.nav`
  width: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  top: 0;
  left: 0;
  z-index: 11;
  overflow: hidden;
  height: ${({ open }) => (open ? "100vh" : "0")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: ${({ open }) =>
    open
      ? "opacity 0.4s ease-in"
      : "opacity 0.3s ease-in, height 0s linear 0.3s"};
`
export const ScrollWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 135px 32px;

  @media ${main.breakpoints.large} {
    padding: 135px 112px;
    flex-direction: row;
  }
`
export const NavigationList = styled.ul`
  list-style-type: none;
  padding: 0 0 62px;
  margin: 0;

  @media ${main.breakpoints.large} {
    padding: 0 0 112px;
  }
`

export const ListItem = styled.li`
  font-size: 2.75rem;
  line-height: 3.25rem;
  margin-bottom: 1rem;
  transition: opacity 0.8s ease, transform 0.8s ease;
  display: flex;
  align-items: flex-start;
  transition-delay: ${({ index, open }) => (open ? index * 0.1 + "s" : "0.3s")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(60px)")};

  &:last-child {
    margin-bottom: 66px;
  }

  &:hover + .hover-img {
    opacity: 1;
    height: auto;
  }

  @media ${main.breakpoints.large} {
    font-size: 5.5rem;
    line-height: 6rem;

    &:last-child {
      margin-bottom: 112px;
    }
  }
`

export const ListNumber = styled.span`
  font-size: 1.125rem;
  line-height: 1.625rem;
  opacity: 0.5;
  margin: 6px 15px 0 0;

  @media ${main.breakpoints.large} {
    margin: 12px 21px 0 0;
  }
`
export const NavLink = styled(props => (
  <AniLink cover bg="#C4C4C4" {...props} />
))`
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
    border-bottom: none;
  }

  &.active {
    opacity: 1;
  }
`

export const HoverImage = styled.div`
  width: 368px;
  position: fixed;
  z-index: -1;
  transform: translate(-50%, -50%);
  display: none;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.6s ease;

  @media ${main.breakpoints.large} {
    display: block;
  }
`

export const ButtonWrapper = styled.div`
  padding-bottom: 112px;
`

export const ContactButton = styled.button`
  ${flexCenter};
  flex-shrink: 0;
  width: 272px;
  height: 272px;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  font-size: 1.375rem;
  transition: ${({ open }) =>
    open
      ? "border-color 0.2s, opacity 0.6s ease 1s"
      : "border-color 0.2s, opacity 0.2s ease"};
  opacity: ${({ open }) => (open ? "1" : "0")};

  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
`

export const BuyButton = styled(props => (
  <AniLink cover bg="#C4C4C4" {...props} />
))`
  ${flexCenter};
  flex-shrink: 0;
  width: 272px;
  height: 272px;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  font-size: 1.375rem;
  transition: ${({ open }) =>
    open
      ? "border-color 0.2s, opacity 0.6s ease 1s"
      : "border-color 0.2s, opacity 0.2s ease"};
  opacity: ${({ open }) => (open ? "1" : "0")};

  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
`

export const AptSelectorButton = styled.button`
  font-size: 2.75rem;
  line-height: 3.25rem;
  transition: opacity 0.8s ease, transform 0.8s ease;
  display: flex;
  align-items: flex-start;
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &:hover + .hover-img {
    opacity: 1;
    height: auto;
  }

  &.active {
    opacity: 1;
  }

  @media ${main.breakpoints.large} {
    font-size: 5.5rem;
    line-height: 6rem;
  }
`

import React from "react"
import PropTypes from "prop-types"
import { Wrapper } from "./styles"

const Button = ({ onClick, title, isInverted, hasBorder, type, icon }) => {
  return (
    <Wrapper
      type={type}
      isInverted={isInverted}
      hasBorder={hasBorder}
      onClick={onClick}
    >
      {icon && icon}
      <span>{title}</span>
    </Wrapper>
  )
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isInverted: PropTypes.bool,
  hasBorder: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.element
}

Button.defaultProps = {
  onClick: () => {},
  isInverted: false,
  hasBorder: false,
  type: "button",
  icon: null
}

export default Button

import { createGlobalState } from 'react-use';
// Apt has been selected in playcanvas.
export const useGlobalPCAptSelected = createGlobalState('');
// Apt has been selected in
export const useGlobalSelectApt = createGlobalState('');
// Switch to next camera
export const useGlobalNextCamera = createGlobalState(false);
// Switch to prev camera
export const useGlobalPrevCamera = createGlobalState(false);
// Deselect all apartments.
export const useGlobalDeselectAllApartments = createGlobalState(false);
// On intro complete
export const useGlobalIntroComplete = createGlobalState(false);
// On camera transition
export const useGlobalCameraTransition = createGlobalState(false);
// On camera transition begin
export const useGlobalCameraTransitionBegin = createGlobalState(false);
// On camera transition end
export const useGlobalCameraTransitionEnd = createGlobalState(false);
// Is apartment selector lightbox open
export const useGlobalAptSelectorOpen = createGlobalState(false);
// Current house in focus
export const useGlobalCurrentHouse = createGlobalState('');
//
export const useGlobalPreloadEnd = createGlobalState(false);
import styled from "styled-components"

export const Main = styled.main`
  padding: 75px 30px 0;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === "blue"
      ? theme.colors.blue
      : backgroundColor === "yellow"
      ? theme.colors.yellow
      : theme.colors.lightGray};
`

import styled from "styled-components"

export const PcLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  height: 100%;
  z-index: 21;

  background-color: ${({ theme }) => theme.colors.black};
  overflow: hidden;

  transition: opacity 0.5s ease-out 0.1s;
  opacity: ${({ fadeOut }) => fadeOut ? '0' : '1'};
`

export const ProgressBar = styled.div`
  width: 150px;
  height: 4px;
  background-color: rgba(196, 196, 196, 0.5);
  margin-top: 32px;
`

export const Progress = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.validationGreen};
  width: ${({ progress }) => progress * 100}%;
`
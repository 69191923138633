export const main = {
  colors: {
    black: "#222222",
    lightBlack: "#333333",
    white: "#FFFFFF",
    red: "#FF3C00",
    green: "#0A5037",
    grayGreen: "#AAB4A0",
    yellow: "#FEF5D2",
    lightGray: "#F1F1F1",
    blue: "#E2EDF4",
    validationGreen: "#8BB054",
    validationRed: "#BF5E49",
    validationOrange: "#E6AB52",
    gray: "#C4C4C4",
  },
  breakpoints: {
    small: `(min-width: 576px)`,
    medium: `(min-width: 768px)`,
    large: `(min-width: 1024px)`,
    xlarge: `(min-width: 1208px)`,
  },
}

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useGlobalAptSelectorOpen } from "../../globalState"
import { HeaderBar, LogoLink } from "./styles"
import MenuButton from "../MenuButton/menuButton"
import Menu from "../Menu/menu"
import Logo from "../../images/logo.svg"

const Header = ({ siteTitle, scrollToForm }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [, setAptSelectorOpen] = useGlobalAptSelectorOpen()

  const showForm = () => {
    setIsMenuOpen(false)
    setTimeout(() => {
      scrollToForm()
    }, 200)
  }

  const openAptSelector = () => {
    setIsMenuOpen(false)
    setAptSelectorOpen(true)
  }

  return (
    <>
      <HeaderBar open={isMenuOpen}>
        <LogoLink to="/" open={isMenuOpen}>
          <Logo width={121} height={62} />
        </LogoLink>
      </HeaderBar>
      <MenuButton
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        isMenuOpen={isMenuOpen}
      />
      <Menu
        closeMenu={() => setIsMenuOpen(false)}
        isMenuOpen={isMenuOpen}
        showForm={showForm}
        openAptSelector={openAptSelector}
      ></Menu>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  scrollToForm: PropTypes.func.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

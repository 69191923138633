import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { StaticImage } from "gatsby-plugin-image"
import _debounce from "lodash.debounce"
import {
  Navigation,
  ScrollWrapper,
  NavigationList,
  ListItem,
  ListNumber,
  NavLink,
  ButtonWrapper,
  ContactButton,
  HoverImage,
  AptSelectorButton,
  // BuyButton,
} from "./styles"

const navItems = [
  {
    link: "/",
    label: "Start",
    image: (
      <StaticImage
        src="../../images/start.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/porthuset/",
    label: "Porthuset",
    image: (
      <StaticImage
        src="../../images/porthuset-fasad.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/gardshuset/",
    label: "Gårdshuset",
    image: (
      <StaticImage
        src="../../images/gardshuset-fasad.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: null,
    label: "Bostadsväljare",
    image: (
      <StaticImage
        src="../../images/bostadsvaljare.png"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/historia/",
    label: "Historia",
    image: (
      <StaticImage
        src="../../images/historia.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/material/",
    label: "Material & tillval",
    image: (
      <StaticImage
        src="../../images/Final_Badrum_86-cropped.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/funktioner/",
    label: "Funktioner",
    image: (
      <StaticImage
        src="../../images/newhouse.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/om-oss/",
    label: "Om oss",
    image: (
      <StaticImage
        src="../../images/detaljbild-tidigare-projekt.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/omradet/",
    label: "Området",
    image: (
      <StaticImage
        src="../../images/omrade.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/dokument/",
    label: "Dokument",
    image: (
      <StaticImage
        src="../../images/originalritning-gard-cropped.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
  {
    link: "/kontakt/",
    label: "Kontakt",
    image: (
      <StaticImage
        src="../../images/porthuset-terrass-cropped.jpg"
        alt="image"
        width={368}
        quality={95}
        formats={["AUTO", "WEBP"]}
      />
    ),
  },
]

const Menu = ({ isMenuOpen, showForm, openAptSelector, closeMenu }) => {
  const scrollRef = useRef(null)
  const hoverImages = useRef([])

  useEffect(() => {
    const scrollEl = scrollRef.current
    if (isMenuOpen) {
      disableBodyScroll(scrollRef.current)
    } else {
      enableBodyScroll(scrollRef.current)
    }
    if (!isMenuOpen && scrollRef.current) {
      setTimeout(() => {
        // Wait until close animation is finnished
        scrollRef.current.scrollTop = 0
      }, 600)
    }
    return () => enableBodyScroll(scrollEl)
  }, [isMenuOpen])

  useEffect(() => {
    if (!hoverImages.current || hoverImages.current.length < 1) return
    document.addEventListener(
      "mousemove",
      _debounce(e => {
        hoverImages.current.forEach(image => {
          if (image && getComputedStyle(image).opacity !== 0) {
            image.style.left = `${e.clientX}px`
            image.style.top = `${e.clientY}px`
          }
        })
      }),
      200
    )
  }, [hoverImages])

  return (
    <Navigation open={isMenuOpen}>
      <ScrollWrapper ref={scrollRef}>
        <NavigationList>
          {navItems.map((item, index) => (
            <div key={item.label} style={{ position: "relative" }}>
              <ListItem index={index} open={isMenuOpen} key={item.label}>
                <ListNumber>
                  {index + 1 <= 9 && "0"}
                  {index + 1}
                </ListNumber>
                {item.link ? (
                  <NavLink activeClassName="active" to={item.link}>
                    <button
                      style={{
                        opacity: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                      }}
                      type="button"
                      onClick={() => {
                        if (window.location.pathname === item.link) {
                          closeMenu()
                        }
                      }}
                    >
                      {" "}
                    </button>
                    {item.label}
                  </NavLink>
                ) : (
                  <AptSelectorButton onClick={openAptSelector}>
                    {item.label}
                  </AptSelectorButton>
                )}
              </ListItem>
              <HoverImage
                ref={element => hoverImages.current.push(element)}
                className="hover-img"
              >
                {item.image && item.image}
              </HoverImage>
            </div>
          ))}
        </NavigationList>
        <ButtonWrapper>
          <ContactButton onClick={showForm} open={isMenuOpen}>
            Anmäl intresse
          </ContactButton>
          {/* <BuyButton open={isMenuOpen} to="/kopanmalan">Köpanmälan</BuyButton> */}
        </ButtonWrapper>
      </ScrollWrapper>
    </Navigation>
  )
}

Menu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  showForm: PropTypes.func.isRequired,
  openAptSelector: PropTypes.func.isRequired,
}

export default Menu

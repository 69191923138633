import styled from "styled-components"

export const Wrapper = styled.button`
  height: 46px;
  border-radius: 48px;
  padding: 0 30px 1px;
  width: fit-content;
  font-size: 1.125rem;
  font-weight: normal;
  background-color: ${({ isInverted, hasBorder, theme }) =>
  hasBorder ? 'transparent' :
    isInverted ? theme.colors.black : theme.colors.lightGray};
  color: ${({ isInverted, theme }) =>
    isInverted ? theme.colors.lightGray : theme.colors.black};
  border: ${({ hasBorder, theme }) =>
    hasBorder && `1px solid ${theme.colors.white}`};
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    z-index: 2;
    display: block;
    position: relative;
  }

  svg {
    z-index: 2;
    margin-right: 10px;
  }

  &::after {
    content: '';
    height: 0;
    width: 100%;
    background-color: ${({ isInverted, theme }) =>
    isInverted ? theme.colors.lightGray : theme.colors.black};
    display: ${({ hasBorder }) =>
    hasBorder ? 'block' : 'none'};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: height 0.3s ease-out;
  }

  &:hover {
    transform: ${({ hasBorder }) =>
      !hasBorder && 'scale(1.04)'};
    color: ${({ hasBorder, isInverted, theme }) =>
      hasBorder && isInverted && theme.colors.black};

    &::after {
      height: 46px;
    }
  }
`

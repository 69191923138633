import axios from 'axios';

const playCanvasPath = '/playcanvas/';
const configFilename = 'config.json';
// const dataFilename = 'data.json';

const instance = axios.create({
  baseURL: playCanvasPath,
  timeout: 30000, // 30 seconds
});

let config = null;

function wait(ms) {
  return new Promise((r) => setTimeout(r, ms));
}
/* eslint-disable-next-line import/no-anonymous-default-export */
export default {
  destroy() {
    config = null;
  },

  async load() {
    if (config) {
      await wait(0);
      return config;
    }

    const configResponse = await instance.get(configFilename).catch(() => null);
    if (!configResponse) {
      return null;
    }


    const { scenes } = configResponse.data;
    const applicationProperties = configResponse.data.application_properties;
    // console.log(applicationProperties);
    config = {
      assetPrefix: playCanvasPath,
      scriptPrefix: playCanvasPath,
      contextOptions: {
        antiAlias: applicationProperties.antiAlias || false,
        use3dPhysics: applicationProperties.use3dPhysics || false,
        preferWebGl2: applicationProperties.preferWebGl2 || true,
        preserveDrawingBuffer:
          applicationProperties.preserveDrawingBuffer || false,
        transparentCanvas: applicationProperties.transparentCanvas || false,
        useLegacyScripts: applicationProperties.useLegacyScripts || false,
        vr: applicationProperties.vr || false,
      },
      scripts: applicationProperties.scripts || [],
      configFilename: `${playCanvasPath}${configFilename}`,
    };

    if (scenes && scenes.length > 0 && scenes[0].url) {
      config.scenePath = scenes[0].url;
    }
    // console.log('scene path ->' ,  config.scenePath );
    if (!config.data) {
      // const dataResponse = await instance.get(dataFilename).catch(() => null);
      // config.data = dataResponse.data;
    }

    return config;
  },
};

import React from "react"
import Arrow from "../../images/arrow.svg"
import { H1style } from "../../styles/typography"
import { Wrapper } from "./styles"
import { useMedia } from "react-use"

const ScrollToTop = ({ isInverted }) => {
  const isMobile = useMedia("(max-width: 576px)")
  return (
    <Wrapper onClick={() => window.scrollTo(0, 0)} inverted={isInverted}>
      <Arrow />
      <H1style size={!isMobile && "large"}>Tillbaka upp</H1style>
      <Arrow />
    </Wrapper>
  )
}

export default ScrollToTop

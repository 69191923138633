import React, { useState, useEffect, useRef } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Button from "../../components/Button/Button"
import ThumbsUp from "../../images/thumbs-up.svg"
import { H2 } from "../../styles/typography"
import {
  FormWrapper,
  StyledForm,
  FormGroup,
  Label,
  Input,
  ThankYouMessage,
  Checkbox,
  GdprText,
} from "./styles"

const Form = ({ formSignal }) => {
  const [formState, setFormState] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isGDPRChecked, setIsGDPRChecked] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    if (formSignal) {
      setTimeout(() => {
        formRef.current.scrollIntoView({ behavior: "smooth" })
      }, 400)
    }
  }, [formSignal])

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const handleToggle = e => {
    setIsGDPRChecked(!isGDPRChecked)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (
      formState.name === "" ||
      formState.lastname === "" ||
      formState.email === "" ||
      formState.phone === "" ||
      !isGDPRChecked
    ) {
      return
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "subject": `Intresseanmälan från www.langgatanssmedja.se - ${formState.name} ${formState.lastName} `,
        ...formState,
      }),
    })
      .then(() => {
        setFormState({
          name: "",
          lastname: "",
          email: "",
          phone: "",
          message: "",
        })

        setShowSuccessMessage(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <FormWrapper ref={formRef}>
      <H2>Anmäl ditt intresse</H2>
      <StyledForm
        onSubmit={handleSubmit}
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div>
          <FormGroup>
            <Input
              type="text"
              name="name"
              onChange={handleChange}
              value={formState.name}
            ></Input>
            <Label inputHasContent={formState.name !== ""}>Förnamn:</Label>
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="lastname"
              onChange={handleChange}
              value={formState.lastname}
            ></Input>
            <Label inputHasContent={formState.lastname !== ""}>
              Efternamn:
            </Label>
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              value={formState.email}
            ></Input>
            <Label inputHasContent={formState.email !== ""}>Email:</Label>
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="phone"
              onChange={handleChange}
              value={formState.phone}
            ></Input>
            <Label inputHasContent={formState.phone !== ""}>
              Telefonnummer:
            </Label>
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <Input
              type="text"
              name="message"
              onChange={handleChange}
              value={formState.message}
            ></Input>
            <Label inputHasContent={formState.message !== ""}>
              Ev. meddelande:
            </Label>
          </FormGroup>
        </div>
        <Checkbox>
          <input
            type="checkbox"
            onChange={handleToggle}
            checked={isGDPRChecked}
          />
          <span></span>
          <GdprText>
            Jag godkänner
            <AniLink cover bg="#C4C4C4" to="/gdpr">
              hantering av personuppgifter
            </AniLink>
          </GdprText>
        </Checkbox>
        <Button isInverted hasBorder type="submit" title="Skicka in" />
      </StyledForm>
      <ThankYouMessage showSuccessMessage={showSuccessMessage}>
        <ThumbsUp />
        <H2>Tack för ditt mail!</H2>
      </ThankYouMessage>
    </FormWrapper>
  )
}

export default Form

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../../styles/globalStyle"
import { main } from "../../styles/theme"
import { Main } from "./styles"
import {
  useGlobalAptSelectorOpen,
  useGlobalPCAptSelected,
} from "../../globalState"
import ApartmentSelector from "../ApartmentSelector/apartmentSelector"
import Header from "../Header/header"
import Footer from "../Footer/footer"

const Layout = ({
  children,
  backgroundColor,
  has4dContactInfo,
  hasForm,
  hasBuyForm,
  hasBuyButton,
  invertedScrollToTop,
}) => {
  const [formSignal, setFormSignal] = useState(0)
  const [aptSelectorOpen, setAptSelectorOpen] = useGlobalAptSelectorOpen()
  const [, setSelectedApt] = useGlobalPCAptSelected()

  useEffect(() => {
    if (!aptSelectorOpen) setSelectedApt("")
  }, [aptSelectorOpen, setSelectedApt])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={main}>
        <GlobalStyle />
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          scrollToForm={() => {
            setFormSignal(formSignal + 1)
          }}
        />

        <Main backgroundColor={backgroundColor}>{children}</Main>
        <Footer
          formSignal={formSignal}
          has4dContactInfo={has4dContactInfo}
          hasForm={hasForm}
          hasBuyForm={hasBuyForm}
          hasBuyButton={hasBuyButton}
          invertedScrollToTop={invertedScrollToTop}
        />

        {aptSelectorOpen && (
          <ApartmentSelector
            closeApartmentSelector={() => {
              setAptSelectorOpen(false)
            }}
          />
        )}
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  hasForm: PropTypes.bool,
  invertedScrollToTop: PropTypes.bool,
}
Layout.defaultProps = {
  backgroundColor: "",
  hasForm: true,
  invertedScrollToTop: false,
}

export default Layout

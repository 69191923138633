export const getTotalRooms = type => {
  switch (type) {
    case "ettor":
      return "1"
    case "enochenhalvor":
      return "1,5"
    case "tvaor":
      return "2"
    case "treor":
      return "3"
    case "fyror":
      return "4"
    default:
      return "1"
  }
}

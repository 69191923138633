import * as React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { main } from "./theme"

export const H1 = styled.h1`
  font-size: ${({ size }) => (size === "large" ? "3.25rem" : "2rem")};
  line-height: ${({ size }) => (size === "large" ? "4rem" : "2.5rem")};
  letter-spacing: ${({ size }) => (size === "large" ? "-0.02em" : "-0.01em")};
  font-weight: normal;
  margin: 0;
  white-space: break-spaces;

  @media ${main.breakpoints.large} {
    font-size: ${({ size }) => (size === "large" ? "9.25rem" : "5.5rem")};
    line-height: ${({ size }) => (size === "large" ? "9.25rem" : "6rem")};
    letter-spacing: ${({ size }) => (size === "large" ? "-0.02em" : "-0.01em")};
  }
`
export const H1style = styled.h2`
  font-size: ${({ size }) => (size === "large" ? "3.25rem" : "2rem")};
  line-height: ${({ size }) => (size === "large" ? "4rem" : "2.5rem")};
  letter-spacing: ${({ size }) => (size === "large" ? "-0.02em" : "-0.01em")};
  font-weight: normal;
  margin: 0;
  white-space: break-spaces;

  @media ${main.breakpoints.large} {
    font-size: ${({ size }) => (size === "large" ? "9.25rem" : "5.5rem")};
    line-height: ${({ size }) => (size === "large" ? "9.25rem" : "6rem")};
    letter-spacing: ${({ size }) => (size === "large" ? "-0.02em" : "-0.01em")};
  }
`

export const H2 = styled.h2`
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
  margin: 0 0 20px;

  @media ${main.breakpoints.large} {
    font-size: 3rem;
    line-height: 3.5rem;
  }
`
export const H3 = styled.h3`
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin: 0;

  @media ${main.breakpoints.large} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`
export const H4 = styled.h4`
  font-size: ${({ size }) => (size === "pre-heading" ? "0.75rem" : "1rem")};
  line-height: ${({ size }) => (size === "pre-heading" ? "1rem" : "1.25rem")};
  font-weight: normal;

  @media ${main.breakpoints.large} {
    font-size: ${({ size }) =>
      size === "pre-heading" ? "0.875rem" : "1.125rem"};
    line-height: ${({ size }) =>
      size === "pre-heading" ? "1.25rem" : "1.5rem"};
  }
`

export const P = styled.p`
  font-size: ${({ size }) => {
    switch (size) {
      case "large":
        return "1.125rem"
      case "small":
        return "0.75rem"
      case "preamble":
        return "1.875rem"
      case "caption":
        return "0.75rem"
      default:
        return "1rem"
    }
  }};
  line-height: ${({ size }) => {
    switch (size) {
      case "large":
        return "1.75rem"
      case "small":
        return "1.625rem"
      case "preamble":
        return "2rem"
      case "caption":
        return "1.125rem"
      default:
        return "1.5rem"
    }
  }};
  letter-spacing: ${({ size }) =>
    size === "preamble" ? "-0.01em" : "inherit"};
  margin: 0 0 1.2rem;

  @media ${main.breakpoints.large} {
    font-size: ${({ size }) => {
      switch (size) {
        case "large":
          return "1.375rem"
        case "small":
          return "0.875rem"
        case "preamble":
          return "1.375rem"
        case "caption":
          return "0.875rem"
        default:
          return "1.125rem"
      }
    }};
    line-height: ${({ size }) => {
      switch (size) {
        case "large":
          return "2rem"
        case "small":
          return "1.25rem"
        case "preamble":
          return "2rem"
        case "caption":
          return "1.25rem"
        default:
          return "1.625rem"
      }
    }};
  }

  a {
    border-bottom: 1px solid ${main.colors.gray};
    transition: border-color .2s;

    &:hover {
      border-color: ${main.colors.black};
    }
  }
`

export const Li = styled.li`
  font-size: 1rem;
  line-height: 1.5rem;

  @media ${main.breakpoints.large} {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
`

export const NavLink = styled(props => <AniLink cover bg="#C4C4C4" {...props} />)`
  height: 46px;
  border-radius: 48px;
  padding: 0 30px 1px;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: normal;
  align-items: center;
  background-color: ${({ isInverted, hasborder }) =>
  hasborder ? 'transparent' :
    isInverted ? `${main.colors.white}` : `${main.colors.black}`};
  color: ${({ hasborder, isInverted }) =>
    hasborder || isInverted ? `${main.colors.black}` : `${main.colors.white}`};
  border: ${({ isInverted, hasborder }) =>
    hasborder && `1px solid ${main.colors.black}`};
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  transition: transform 0.2s ease;
  position: relative;
  overflow: hidden;

  span {
    z-index: 2;
    display: block;
    position: relative;
  }

  &::after {
    content: '';
    height: 0;
    width: 100%;
    background-color: ${({ isInverted }) =>
    isInverted ? main.colors.lightGray : main.colors.black};
    display: ${({ hasborder }) =>
    hasborder ? 'block' : 'none'};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: height 0.3s ease-out;
  }

  &:hover {
    transform: ${({ hasborder }) =>
      !hasborder && 'scale(1.04)'};
    color: ${({ hasborder }) =>
      hasborder && main.colors.white};

    &::after {
      height: 46px;
    }
  }
`

export const ExternalLink = styled.a`
  height: 46px;
  border-radius: 48px;
  padding: 0 30px 1px;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: normal;
  align-items: center;
  background-color: ${({ isInverted }) =>
    isInverted ? `${main.colors.white}` : `${main.colors.black}`};
  color: ${({ isInverted }) =>
    isInverted ? `${main.colors.black}` : `${main.colors.white}`};
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.04);
  }
`

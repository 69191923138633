import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import { main } from "../../styles/theme"
import { flexCenter, gridLayout } from "../../styles/mixins"

export const StyledFooter = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const DocumentButtonWrapper = styled.div`
  position: absolute;
  ${gridLayout}
  top: -44px;

  padding: 0 30px;
  @media ${main.breakpoints.medium} {
    top: -3%;
  }
  @media ${main.breakpoints.large} {
    top: -4%;
  }
`

export const DocumentButton = styled(props => (
  <AniLink cover bg="#C4C4C4" {...props} />
))`
  ${flexCenter};
  display: grid;
  grid-column: 12 / span 12;
  font-size: 1rem;
  column-gap: 40px;

  position: relative;
  border-radius: 50%;
  width: 100%;
  height: auto;
  padding-top: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.black};
    transition: transform 0.2s ease;
  }

  &::after {
    color: ${({ theme }) => theme.colors.white};
    content: "Köpanmälan";
    text-align: center;
    width: 100%;
    position: absolute;
  }

  &:hover {
    &::before {
      transform: scale(1.125);
      transition: transform 0.4s ease;
    }
  }

  @media ${main.breakpoints.small} {
    grid-column: 10 / span 4;
  }
  @media ${main.breakpoints.medium} {
    grid-column: 12 / span 4;
  }

  @media ${main.breakpoints.large} {
    font-size: 1.25rem;
    grid-column: 12 / span 4;
  }

  @media ${main.breakpoints.xlarge} {
    grid-column: 10 / span 2;
    font-size: 1.375rem;
  }
`

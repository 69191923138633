import styled from "styled-components"
import { main } from "../../styles/theme"
import { gridLayout } from "../../styles/mixins"

export const Wrapper = styled.section`
  ${gridLayout}

  padding: 60px 30px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${main.breakpoints.large} {
    padding: 80px 30px 107px;
  }
`

export const HeadingWrapper = styled.div`
  grid-column: 1 / -1;
`

export const Content = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;

  @media ${main.breakpoints.small} {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 40px;
    grid-column: 4 / span 10;
  }

  @media ${main.breakpoints.large} {
    grid-column: 6 / span 8;
  }

  @media ${main.breakpoints.xlarge} {
    grid-column: 6 / span 6;
  }
`
export const ImageBlock = styled.div`
  margin-top: 40px;
  grid-column-start: 2;

  img {
    width: 100%;
  }
  > h3 {
    margin: 37px 0 40px;
  }

  > p {
    margin-bottom: 4px;
    > a {
      color: inherit;
      text-decoration: none;
      position: relative;
      opacity: 1;
      border: none;

      &::before {
        content: "";
        display: block;
        width: 0px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.black};
        position: absolute;
        left: 0;
        bottom: 0;
        transition: opacity 0.2s ease-out, width 0.2s ease-out;
        opacity: 0;
      }

      &:hover {
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  @media ${main.breakpoints.medium} {
    margin-top: 0;
  }
`

export const InfoBlock = styled.div`
  padding: 60px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;

  @media ${main.breakpoints.medium} {
    ${gridLayout}
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 40px;
  }

  @media ${main.breakpoints.large} {
    padding: 80px 30px 107px;
    grid-column: 6 / span 8;
  }

  @media ${main.breakpoints.xlarge} {
    grid-column: 6 / span 6;
  }
`

export const ContactInfo = styled.div`
  margin-top: 33px;

  @media ${main.breakpoints.medium} {
    margin-top: 0;
  }

  > p {
    margin-bottom: 4px;
    white-space: break-spaces;

    > a {
      color: inherit;
      text-decoration: none;
      position: relative;
      opacity: 1;
      border: none;

      &::before {
        content: "";
        display: block;
        width: 0px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.black};
        position: absolute;
        left: 0;
        bottom: 0;
        transition: opacity 0.2s ease-out, width 0.2s ease-out;
        opacity: 0;
      }

      &:hover {
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
`
